@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sacramento&display=swap');
.modal-backdrop.in{ opacity: 0; }
.modal-box .modal-dialog{
    width: 750px;
    margin: 50px auto 0;
}
.modal-content{
    background: #fff;
    border-radius: 30px;
    border: none;
    box-shadow: 0 0 30px rgba(0,0,0,0.3);
    overflow: hidden;
}
.modal-content .close{
    color: #747474;
    background-color:rgba(255,255,255,0.5);
    font-size: 30px;
    font-weight: 300;
    text-shadow: none;
    line-height: 32px;
    height: 27px;
    width: 27px;
    padding: 0;
    border-radius: 50%;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    left: auto;
    right: 15px;
    top: 15px;
    z-index: 2;
    transition: all 0.3s ease 0s;
}
.modal-content .close span{
    margin: -2px 0 0 0;
    display: block;
}
.modal-body .close:hover{ color: #747474; }
.modal-body{ padding: 0 !important; }
.modal-image{ padding: 0 !important; }

.modal-image img{
    height: 100%;
    width: 100%;
}
.modal-body .content{ padding: 36px 30px; }

.modal-body .title{
    color: #fb3640;
    font-family: 'Sacramento', cursive;
    font-weight: bold;
    font-size: 35px;
    font-weight: 500;
    margin: 0 0 30px;
}

.modal-body .sub-title{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 20px;
    display: block;
}
.modal-body .description{
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    margin: 0 0 30px;
}

.modal-body .btn{
    color: #fff;
    background-color: #0ca2e2;
    text-align: center;
    align-items: center;
    font-size: 20px;
    font-weight: 200;
    letter-spacing: 1px;
    width: 155px;
    padding: 5px;
    border-radius: 25px;
    border: none;
    outline: 1px solid rgba(255, 255,255, 0.6);
    outline-offset: -5px;
    transition: all 0.4s ease 0s;
}
.modal-body .btn:hover{
    color: #fff;
    background-color: #fb3640;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 767px){
    .modal-box .modal-dialog{ width: 95% !important; }
    .modal-box .modal-dialog .modal-content .modal-body .modal-image{
        height: 250px;
        overflow: hidden;
    }
    .modal-box .modal-dialog .modal-content .modal-body .modal-image img{ height: auto; }
    .modal-box .modal-dialog .modal-content .modal-body .content{ 
        text-align: center;
        padding: 30px;
    }
    .modal-box .modal-dialog .modal-content .modal-body .content .sub-title{ font-size: 25px; }
}