h1{ padding: 0 0 30px; }
/*#news-slider{
    margin-top: 10px;
}*/
.post-slide{
    margin: 0 0px;
    margin-top: 0px;
    position: relative;
}
.post-slide .post-date{
    width: 60px;
    background: #a2397b;
    float: right;
    padding: 0px 0;
    margin-top: 0px;
    margin-left: -45px;
}
.post-slide:hover .post-date{
    background:#3498db;
}
.post-slide .date{
    font-size:25px;
    color:#fff;
    display: block;
    text-align:center;
    font-weight: bold;
}
.post-slide .month{
    font-size: 14px;
    color:#fff;
    display: block;
    text-align:center;
    text-transform:uppercase;
}
.author-img{
    position: absolute;
    left:10%;
    padding: 0px;
    background: #fff;
    display: inline-block;
}
.post-slide .post-content{
    padding-left: 0px;
}
.post-slide .post-content img{
    width: 100%;
    height: 100%;
}
.post-content a{
    text-decoration: none;
}
.post-slide .post-title{
    margin:5px 0 5px 0;
}
.post-slide .post-title a{
    font-size:20px;
    font-weight:bold;
    color:#333;
    display: inline-block;
    text-transform:uppercase;
    transition: all 0.3s ease 0s;
    text-decoration: none;
}
.post-slide .post-title a:hover{
    color:#3498db;
    text-decoration: none;
}
.post-slide .post-description{
    font-size: 14px;
    line-height:24px;
    color:#808080;
    margin-bottom:5px;
    font-weight:bold;
}
.truncate-3-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical; /* Ensure the box's children (text) are arranged vertically */
    overflow: hidden; /* Hide any content that overflows the 3 lines */
    text-overflow: ellipsis; /* Optional: Add ellipsis (...) at the end of the truncated text */
  }
.post-slide .read-more{
    display: inline-block;
    color:#fff;
    padding:5px 3px;
    border-radius:2px;
    border-bottom:4px solid #226595;
    transition: color 0.20s linear;
}
.post-slide .read-more:hover{
    text-decoration:none;
    background: #226595;
}
.post-slide .read-more i{
    margin-left:10px;
    font-size:10px;
}

.owl-theme .owl-controls .owl-buttons div{
    border-radius: 0;
}
.owl-theme .owl-controls .owl-buttons{
    margin: 10px;
    position: absolute;
    top: -9%;
    width: 100%;
    text-align: center;
}
.owl-theme .owl-controls .owl-buttons .owl-prev,
.owl-theme .owl-controls .owl-buttons .owl-next{
    display: inline-block;
    padding: 8px 20px;
    background:#fff;
    border: 5px solid #444;
    margin-right:30px;
    transition:background 0.50s ease;
}
.owl-theme .owl-controls .owl-buttons .owl-prev:hover,
.owl-theme .owl-controls .owl-buttons .owl-next:hover{
    background:#3498db;
    border-color: #3498db;
}
.owl-theme .owl-controls .owl-buttons .owl-prev:after,
.owl-theme .owl-controls .owl-buttons .owl-next:after{
    content:"\f104";
    font-family: FontAwesome;
    color: #444;
    font-size:26px;
    line-height: 26px;
}
.owl-theme .owl-controls .owl-buttons .owl-next:after{
    content:"\f105";
}
.owl-theme .owl-controls .owl-buttons .owl-prev:hover:after,
.owl-theme .owl-controls .owl-buttons .owl-next:hover:after{
    color:#fff;
}
@media only screen and (max-width: 480px){
    .post-slide .post-date{
        width: 70px;
    }
    .post-content{
        padding-left: 85px;
    }
}